import { Stack } from '@fluentui/react';
import React from 'react';
import { AppTheme } from './App';

interface InputProps {
    theme: AppTheme;
    defaultNames: string[];
}

const Input: React.FC<InputProps> = (props) => {

    const [names, setNames] = React.useState<string[]>(props.defaultNames);

    React.useEffect(() => {
        setNames(props.defaultNames.concat(''));
    }, [props.defaultNames]);

    // const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    //     const names = e.target.value.split('\n');
    //     console.log(names)
    //     localStorage.setItem('names', JSON.stringify(names.filter(name => name !== '').map(name => name.trim()).flat()));
    //     setNames(names);
    // }

    const setValue = (index: number, value: string) => {
        console.log(index, value)
        const newNames = [...names];
        newNames[index] = value.replace('\n', '');
        if (newNames[newNames.length - 1] !== '') { newNames.push('') }
        // if (newNames.filter((name, index) => index !== newNames.length - 1 && name === '').length > 0) {
        //     newNames.splice(index, 1);
        // }
        localStorage.setItem('names', JSON.stringify(newNames.filter(name => name !== '').map(name => name.trim()).flat()));
        setNames(newNames);
    }

    const handleKeyPress = (index: number, event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        switch (event.key) {
            case 'Enter':
            case 'ArrowDown':
                event.preventDefault();
                const nextIndex = index + 1;
                if (nextIndex < names.length) {
                    document.getElementById(`textbox-${nextIndex}`)!.focus();
                }
                break;
            case 'Backspace':
            case 'Delete':
                if (names![index]) { return }
                const newNames = [...names];
                newNames.splice(index, 1);
                setNames(newNames);
                if (index > 0) {
                    document.getElementById(`textbox-${index - 1}`)!.focus();
                }
                break;
            case 'ArrowUp':
                event.preventDefault();
                if (index > 0) {
                    document.getElementById(`textbox-${index - 1}`)!.focus();
                }
                break;
            default:
                break;
        }
    };


    const handleUpdateURL = () => {
        const encodedNames: string[] = names.filter(name => name !== '').map(encodeURIComponent);
        const urlParams: string = encodedNames.join(',');
        window.history.pushState({ path: `?theme=${props.theme}&default=${urlParams}` }, '', `?theme=${props.theme}&default=${urlParams}`)
        window.location.search = `?theme=${props.theme}&names=${urlParams}`;
    }

    const copyUrl = () => {
        const url = names.length > 0 ? `https://fikahjulet.se?theme=${props.theme}&default=${names.filter(name => name !== '').map(encodeURIComponent).join(',')}` : 'https://fikahjulet.se'
        navigator.clipboard.writeText(url);
    }

    return (

        <><Stack enableScopedSelectors wrap styles={{ inner: { maxHeight: '50vh' } }} tokens={{ childrenGap: '5px' }}>
            {names.map((name, index) => {
                return (
                    <textarea
                        key={`textbox-${index}`}
                        id={`textbox-${index}`}
                        className='TextRow'
                        rows={1}
                        cols={20}
                        value={name}
                        onChange={(e) => setValue(index, e.target.value)}
                        onKeyUp={(e) => handleKeyPress(index, e)}
                        placeholder="Lägg till ett namn här"
                    />
                )
            })}
        </Stack>
            <Stack>
                <Stack.Item align='center'>
                    <button className={`Button ${props.theme}-button`} onClick={handleUpdateURL}>Skapa {props.theme}!</button>
                </Stack.Item>
                <Stack.Item align='center'>
                    <button className={`Button ${props.theme}-button`} onClick={copyUrl}>Kopiera länk med namn</button>
                </Stack.Item>
            </Stack>
        </>
    )
}

export default Input;