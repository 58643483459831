import React from 'react';
import { Image } from '@fluentui/react';
import { AppTheme } from './App';

interface HeaderProps {
    theme: AppTheme;
}

const Header: React.FC<HeaderProps> = (props) => {
    if (props.theme === 'tomtbola') {
        return ((<Image src='./tomtbola-logo.svg' styles={{image: {width: '100%',maxHeight: '15vh'}}} />))
    }
    return (
        <svg className="HeaderSvg" version="1.0" xmlns="http://www.w3.org/2000/svg" width="auto" height="10vw" viewBox="0 0 629 123"  >
            <g transform="translate(0,123) scale(0.1,-0.1)"
                fill="#ffffff" stroke="none">
                <path fill="#ffffff" d="M150 1210 c-20 -38 4 -85 68 -130 35 -24 62 -52 69 -70 13 -34 47
-41 61 -12 18 36 -6 81 -67 125 -32 23 -65 55 -72 72 -17 37 -44 44 -59 15z"/>
                <path fill="#ffffff" d="M402 1218 c-32 -32 2 -99 76 -147 30 -19 52 -41 54 -53 7 -49 68 -49
68 -1 -1 41 -20 70 -76 109 -27 19 -56 49 -64 67 -16 35 -39 44 -58 25z"/>
                <path fill="#ffffff" d="M652 1218 c-31 -31 -2 -92 68 -142 28 -20 55 -48 60 -61 12 -31 35
-41 55 -25 36 30 7 89 -71 143 -30 22 -54 46 -54 56 0 19 -18 41 -35 41 -6 0
-16 -5 -23 -12z"/>
                <path fill="#ffffff" d="M1895 1058 c-40 -21 -75 -62 -81 -92 -11 -63 33 -129 99 -147 51 -13
118 10 147 51 28 39 27 108 -2 145 -37 48 -117 69 -163 43z"/>
                <path fill="#ffffff" d="M4075 1058 c-40 -21 -75 -62 -81 -92 -11 -63 33 -129 99 -147 51 -13
118 10 147 51 28 39 27 108 -2 145 -37 48 -117 69 -163 43z"/>
                <path fill="#ffffff" d="M2250 1046 l-95 -11 -3 -392 -2 -393 120 0 120 0 0 93 0 92 51 -92
50 -93 130 0 c71 0 129 3 129 6 0 4 -36 64 -80 134 -44 70 -80 130 -80 133 0
4 24 44 53 89 30 46 66 102 80 126 l27 42 -129 0 -129 0 -49 -86 -48 -87 -3
227 -2 226 -23 -1 c-12 -1 -65 -7 -117 -13z"/>
                <path fill="#ffffff" d="M3458 1047 l-98 -9 0 -394 0 -394 115 0 115 0 0 153 c0 162 9 197 50
197 44 0 50 -22 50 -191 l0 -159 120 0 120 0 0 183 c0 206 -11 266 -57 309
-43 40 -67 48 -135 48 -44 0 -76 -6 -103 -20 -22 -11 -41 -20 -42 -20 -2 0 -3
70 -3 155 0 142 -2 155 -17 154 -10 -1 -62 -6 -115 -12z"/>
                <path fill="#ffffff" d="M5078 1047 l-98 -9 0 -394 0 -394 120 0 120 0 -2 403 c-3 394 -3 402
-23 403 -11 1 -64 -3 -117 -9z"/>
                <path fill="#ffffff" d="M1270 645 l0 -395 120 0 120 0 0 135 0 135 105 0 106 0 -3 108 -3
107 -102 3 -103 3 0 39 0 40 131 0 131 0 -6 28 c-3 15 -10 64 -17 110 l-11 82
-234 0 -234 0 0 -395z"/>
                <path fill="#ffffff" d="M6050 914 l-115 -53 -3 -41 c-3 -37 -5 -40 -33 -40 l-29 0 0 -90 0
-90 29 0 28 0 5 -104 c6 -140 29 -189 108 -229 50 -26 62 -28 140 -25 47 2 91
7 98 12 8 5 12 37 12 100 l0 94 -43 -5 c-62 -7 -77 11 -77 93 l0 64 60 0 60 0
0 90 0 90 -60 0 -59 0 -3 94 -3 94 -115 -54z"/>
                <path fill="#ffffff" d="M72 783 c3 -141 5 -151 34 -213 17 -36 46 -83 64 -103 l32 -37 -101
0 -101 0 0 -50 c0 -61 18 -106 55 -140 l27 -25 433 0 433 0 27 25 c37 34 55
79 55 140 l0 50 -121 0 -121 0 35 39 c20 22 43 54 52 70 14 29 20 31 68 31 63
0 127 28 154 66 78 109 25 255 -103 284 -21 5 -239 10 -483 10 l-443 0 4 -147z
m778 -16 c0 -145 -44 -237 -144 -304 l-48 -33 -162 0 -163 0 -44 30 c-96 64
-140 151 -147 293 l-5 97 357 0 356 0 0 -83z m181 54 c32 -32 38 -79 18 -125
-14 -29 -64 -56 -106 -56 l-28 0 3 105 3 105 40 0 c31 0 47 -7 70 -29z m-77
-483 c-15 -49 -2 -48 -440 -48 l-413 0 -15 22 c-35 49 -46 48 430 48 l445 0
-7 -22z"/>
                <path fill="#ffffff" d="M5497 790 c-136 -35 -217 -137 -217 -275 0 -197 177 -322 390 -276
41 9 143 51 158 65 2 3 -25 83 -46 137 l-11 26 -66 -33 c-51 -26 -80 -34 -121
-34 -29 0 -55 4 -58 8 -3 5 69 33 159 62 124 40 165 58 165 70 0 80 -75 191
-155 229 -53 25 -144 34 -198 21z m114 -170 c10 -6 19 -14 19 -18 0 -7 -125
-52 -143 -52 -18 0 -4 31 24 54 32 27 67 33 100 16z"/>
                <path fill="#ffffff" d="M1995 785 c-5 -2 -45 -6 -87 -10 l-78 -7 0 -259 0 -259 120 0 120 0
0 270 0 270 -32 -1 c-18 0 -37 -2 -43 -4z"/>
                <path fill="#ffffff" d="M2887 783 c-34 -5 -43 -22 -52 -101 l-7 -63 28 8 c16 4 62 8 102 8
66 0 76 -3 93 -24 11 -13 18 -25 17 -27 -2 -1 -27 2 -56 7 -108 20 -218 -28
-252 -111 -20 -47 -8 -132 23 -173 55 -72 167 -97 255 -57 23 10 42 15 42 10
0 -6 45 -10 103 -10 l103 0 -4 193 c-2 105 -8 206 -13 224 -14 44 -69 89 -131
108 -48 14 -181 18 -251 8z m171 -325 c7 -7 12 -21 12 -33 0 -28 -19 -45 -50
-45 -31 0 -50 17 -50 45 0 28 19 45 50 45 14 0 31 -5 38 -12z"/>
                <path fill="#ffffff" d="M4175 785 c-5 -2 -45 -6 -87 -10 l-78 -7 0 -264 c0 -251 -1 -265 -20
-284 -11 -11 -27 -20 -35 -20 -12 0 -15 -18 -15 -100 l0 -100 48 6 c119 14
193 59 234 141 22 47 23 57 26 346 l4 297 -34 -1 c-18 0 -37 -2 -43 -4z"/>
                <path fill="#ffffff" d="M4495 785 c-5 -2 -45 -6 -87 -10 l-78 -7 0 -192 c0 -212 6 -243 53
-294 54 -57 167 -67 243 -23 27 16 34 17 34 6 0 -12 21 -15 115 -15 l115 0 0
271 0 271 -52 -6 c-29 -3 -81 -9 -115 -12 l-63 -7 0 -141 c0 -154 -9 -189 -50
-194 -44 -7 -50 17 -50 195 l0 163 -27 -1 c-16 0 -32 -2 -38 -4z"/>
            </g>
        </svg>
    )
}
export default Header