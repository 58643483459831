import React, { useEffect, useRef, useState } from 'react';
import { Image, Stack } from '@fluentui/react';
import './App.css';
import html2canvas from 'html2canvas';
import Header from './Header';
import Wheel from './Wheel';
import Input from './Input';
import Footer from './Footer';
import Snow from './Snow';
import Rehnis from './Rehnis';

export type AppTheme = 'fikahjulet' | 'tomtbola';

const App: React.FC = () => {

  const [theme, setTheme] = useState<AppTheme>('fikahjulet');

  const [innerRotation, setInnerRotation] = useState<number>(0);
  const [outerRotation, setOuterRotation] = useState<number>(0);

  const [defaultNames, setDefaultNames] = useState<string[]>([])
  const [allNames, setAllNames] = useState<string[]>([])
  const [innerNames, setInnerNames] = useState<string[]>([])
  const [outerNames, setOuterNames] = useState<string[]>([])

  const [isCapturing, setIsCapturing] = useState<boolean>(false);
  const captureRef = useRef<HTMLDivElement>(null);


  // ?default=Anders,Benjamin,Dean,Elisabeth,Elvira,Erik,Fredrik,Jenny,Joanna,Jonas,Josefine,Kristina,Linda,Malin,Maria,Marie,Monica,Måns,Nataliia,Nils A,Nils H,Sandra,Sara,Åsa
  // ?default=Anders,Benjamin,Dean,Elisabeth,Elvira,Erik,Fredrik,Jenny,Joanna,Jonas,Josefine,Kristina,Linda,Malin,Maria,Marie,Monica,Måns,Nataliia,Nils A,Nils H,Sandra,Sara,Åsa

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const nameParam = queryParams.get('names');
    const defaultParam = queryParams.get('default');
    const defaultTheme = queryParams.get('theme');

    if (defaultParam) {
      setDefaultNames(defaultParam.split(',').map(n => n.trim()))
    }
    else {
      setDefaultNames(JSON.parse(localStorage.getItem('names')!) || [])
    }

    if (nameParam) {
      setAllNames(nameParam.split(',').map(n => n.trim()))
    }

    if (defaultTheme) {
      setTheme(defaultTheme as AppTheme)
    }
    else {
      console.log(localStorage.getItem('theme'))
      console.log(localStorage.getItem('theme') as AppTheme)
      setTheme(localStorage.getItem('theme') as AppTheme === 'tomtbola' ? 'tomtbola' : 'fikahjulet')
    }

  }, []);

  useEffect(() => {
    const [innerArray, outerArray] = splitArrayRandomly(allNames);
    setInnerNames(innerArray)
    setOuterNames(outerArray)
  }, [allNames])

  const setNewTheme = (theme: AppTheme) => {
    setTheme(theme);
    localStorage.setItem('theme', theme);
  }

  //split the array in two random parts and make them the same length
  function splitArrayRandomly(arr: string[]): [string[], string[]] {
    const length = arr.length;
    const middle = Math.floor(length / 2);
    const shuffledArr = [...arr].sort(() => Math.random() - 0.5);

    const firstHalf = shuffledArr.slice(0, middle);
    const secondHalf = shuffledArr.slice(middle);

    while (firstHalf.length !== secondHalf.length) {
      if (firstHalf.length > secondHalf.length) {
        secondHalf.push(".");
      } else {
        firstHalf.push(".");
      }
    }

    return [firstHalf, secondHalf];
  }

  const handleCapture = () => {
    if (captureRef.current) {
      setIsCapturing(true);
      setTimeout(() => {
        html2canvas(captureRef.current!).then(function (canvas) {
          const imgData = canvas.toDataURL('image/png');

          const link = document.createElement('a');
          link.href = imgData;
          link.download = 'fikahjul.png';
          link.click();
        });
        setIsCapturing(false);
      }, 10);

    }
  };

  const handleSegmentClick = () => {
    const randomSegments1 = Math.floor(Math.random() * innerNames.length);
    const randomSegments2 = Math.floor(Math.random() * outerNames.length);
    const newInnerRotation = innerRotation - 180 - (randomSegments1 * (360 / innerNames.length));
    const newOuterRotation = outerRotation + 180 + (randomSegments2 * (360 / outerNames.length));
    setInnerRotation(newInnerRotation);
    setOuterRotation(newOuterRotation);
  }

  const saveBackground: React.CSSProperties = isCapturing ? { backgroundColor: theme === 'tomtbola' ? '#d9d9d9' : '#251b30' } : {}

  let main: JSX.Element = <></>

  if (allNames.length < 3) {
    main = <div className='SaveToPng' ref={captureRef}>
      <Stack verticalAlign='center' tokens={{ childrenGap: '2em' }}>
        <Stack.Item align='center'>
          <Header
            theme={theme}
          />
        </Stack.Item>
        {allNames.length > 0 && <Stack.Item align='center'>
          <h2>Måste ha mer än två namn!</h2>
        </Stack.Item>}
        <Stack.Item align='center'>
          <Input
            theme={theme}
            defaultNames={defaultNames}
          />
        </Stack.Item>
      </Stack>
    </div>
  }
  else {
    main = <>
      <div className='SaveToPng' ref={captureRef} style={saveBackground}>
        <Stack verticalAlign='center' tokens={{ childrenGap: '2em' }}>
          <Stack.Item align='center'>
            <Header
              theme={theme}
            />
          </Stack.Item>
          <Stack.Item align='center' >
            <Wheel
              theme={theme}
              onClick={handleSegmentClick}
              innerNames={innerNames}
              outerNames={outerNames}
              innerRotation={innerRotation}
              outerRotation={outerRotation}
            />
          </Stack.Item>
        </Stack>
      </div>
      <button className={`Button ${theme}-button`} onClick={handleCapture}>Spara som bild</button>
    </>
  }

  console.log(theme)
  return (
    <Stack className={`App ${theme}`} horizontalAlign='center'>
      {/* <Snow theme={theme} /> */}

      {theme === 'tomtbola' && <Image className='floating-image' src='./tomtbola.svg' styles={{ image: { width: '40vw', maxWidth: '400px' } }} />}
      <Stack.Item className='in-front' align='center' grow>
        {main}
      </Stack.Item>
      <Stack.Item className='Logo' align='center'>
        <Footer
          theme={theme}
          setTheme={setNewTheme}
        />
      </Stack.Item>
      {(theme === 'tomtbola') && <Rehnis theme={theme} isInput={allNames.length < 3} />}
    </Stack>
  );
}

export default App;
