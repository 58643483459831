import React from "react";
import { Image, Link, Stack } from "@fluentui/react";
import { AppTheme } from "./App";

interface FooterProps {
    theme: AppTheme;
    setTheme: (theme: AppTheme) => void;
}

const Footer: React.FC<FooterProps> = (props) => {
    return (
        <Stack horizontalAlign="center" tokens={{ childrenGap: '1rem' }}>
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: '2rem' }}>
                <Stack.Item>
                    <Link onClick={() => props.setTheme("fikahjulet")}>
                        <Image src='./fikahjul.png' width={250} height={49} />
                    </Link>
                </Stack.Item>
                <Stack.Item>
                    <Link onClick={() => props.setTheme("tomtbola")}>
                        <Image src='./tomtbola-logo.svg' width={250} height={88} />
                    </Link>
                </Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item>
                    <Link href='https://www.rehngruppen.se' target='_blank'>
                        <Image src='./rehnlogo.png' width={251} height={38} />
                    </Link>
                </Stack.Item>
            </Stack>
        </Stack>
    )
}

export default Footer;