
import React from "react";
import { AppTheme } from "./App";
import { Image } from "@fluentui/react";
import "./Snow.css";

interface RehnisProps {
    theme: AppTheme;
    isInput: boolean;
}

const Rehnis: React.FC<RehnisProps> = (props) => {
    const [showText, setShowText] = React.useState<boolean>(true);
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)


    React.useEffect(() => {
        setTimeout(() => {
            setShowText(false);
        }, 3000);
    }, []);

    React.useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="rehnis" >
            {!props.isInput && <div className="speech-bubble" style={{ fontSize: 'clamp(10px, 2vh, 16px)', fontWeight: 'bolder' }} >
                Glöm inte att spara bilden!
            </div>}
            {((!showText && screenWidth < 1350) && props.isInput) &&
                <div
                    className="speech-bubble"
                    style={{ fontSize: 'clamp(10px, 2vh, 16px)', fontWeight: 'bolder' }}
                    onClick={() => setShowText(true)}
                    onMouseEnter={() => setShowText(true)}
                >
                    Behöver du hjälp?
                </div>}
            {((showText || screenWidth >= 1350) && props.isInput) &&
                <div
                    className="speech-bubble"
                    style={{
                        fontSize: 'clamp(10px, 2vh, 16px)',
                        fontWeight: 'bolder'
                    }}
                    onClick={() => setShowText(false)}
                    onMouseLeave={() => setShowText(false)}
                >
                    <p style={{ fontSize: 'clamp(10px, 2vh, 16px)', fontWeight: 'bolder' }}>Hej och välkommen till Tomtbolan - Verktyget som slumpar par indelningen på ett så rättvist sätt som möjligt!</p>
                    <p>Gör så här för att sätta igång:</p>
                    <ul style={{ textAlign: 'left' }}>
                        <li>Skriv in namnen som ska ingå i parindelningen!</li>
                        <li>Klicka på 'skapa Tomtbola'</li>
                        <li>Tryck på 'Start' för att generera parindelningen!</li>
                        <li>Nu har du en slumpmässigt resultat. Spara ned resultatet som en bild för att komma ihåg indelningen.</li>
                    </ul>
                    <p>Enklare än så blir det inte! God jul och gott nytt år önskar Rehngruppen</p>
                </div>
            }
            <Image
                src='./tomterehnis.png'
                styles={{ image: { maxHeight: '25vh' } }}
                onClick={() => setShowText(!showText)}
                onMouseEnter={() => setShowText(true)}
                onMouseLeave={() => setShowText(false)}
            />

        </div>
    )
}

export default Rehnis;