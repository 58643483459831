import React from 'react';
import Ring from "./Ring";
import { AppTheme } from './App';

interface WheelProps {
    theme: AppTheme;
    onClick: () => void;
    innerNames: string[];
    outerNames: string[];
    innerRotation: number;
    outerRotation: number;
}

const Wheel: React.FC<WheelProps> = ({ theme, onClick, innerNames, outerNames, innerRotation, outerRotation }) => {
    const [buttonRotation, setButtonRotation] = React.useState<number>(1);

    React.useEffect(() => {
        setButtonRotation(buttonRotation === 0 ? 360 : 0);
    }, [innerRotation]);

    return (
        <svg className='WheelSvg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" width="auto" height="100%" onClick={onClick}    >
            <g className="animated-rotation" transform={`rotate(${innerRotation})`}          >
                <Ring theme={theme} strings={innerNames} cx={200} cy={200} innerRadius={120} outerRadius={160} />
            </g>
            <g className="animated-rotation" transform={`rotate(${outerRotation})`}          >
                <Ring theme={theme} strings={outerNames} cx={200} cy={200} innerRadius={160} outerRadius={200} />
            </g>
            <g className="animated-rotation" transform={`rotate(${buttonRotation})`}    >
                {/* {theme === "tomtbola" && <image href='./tomtbola-starta.png' x={125} y={125} height={150} width={150} />}                 */}
                <g>
                    <circle cx="200" cy="200" r="75" fill={theme === 'tomtbola' ? '#f27656' : '#572a88  '} />
                    <text
                        x="50%"
                        y="50%"
                        dominant-baseline="middle"
                        text-anchor="middle"
                        fill="white"
                        fontSize="28"
                        fontWeight="700"
                        fontFamily='neue-kabel, sans-serif'
                    >Starta</text>
                </g>
            </g>
        </svg>
    )
}

export default Wheel;